import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
  Link
} from "react-router-dom";

import FadeIn from '../shared/fadein';
import { Helmet } from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, A11y } from 'swiper';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'

export default function About() {

  const [data, setData] = useState([]);

  const url = 'https://dginnovate.herokuapp.com/';

  useEffect(() => {
    axios
      .get(`${url}about`)
      .then(function(response) {
        setData(response.data)
      })
      .catch(error => console.log(error));

  }, []);

  SwiperCore.use([ Pagination ]);

  const helmetData = (
    <Helmet>
        <meta name="description" content={`${data.metaDescription}`} />
        <title>{`${data.metaTitle}`}</title>
        <link rel="canonical" href="https://www.dgiplc.com/about"/>
        <meta property="og:title" content={`${data.metaTitle}`} key="og:title" />
        <meta
          property="og:description"
          content={`${data.metaDescription}`}
          key="og:description"
        />
        <meta name="keywords" content={`${data.keywords}`} />

       {data.preventIndexing ? (
         <meta name="robots" content="noindex"></meta>
       ) : null }
       {data.preventIndexing ? (
         <meta name="googlebot" content="noindex"></meta>
       ) : null }
    </Helmet>
  );

  return (
    <>
    {helmetData}
    <div className="header-banner pd-h">
      <h1>About us</h1>
    </div>
    <div className="container pd-h-tch">
      <FadeIn delay={200} duration={1200}>
      <div>
        <div className="paragraph1">
          <div dangerouslySetInnerHTML={{__html: data.paragraph1 }}></div>
          <div dangerouslySetInnerHTML={{__html: data.video1 }}></div>
        </div>
        <div className="paragraph2">
          <div dangerouslySetInnerHTML={{__html: data.paragraph2 }}></div>
          <div dangerouslySetInnerHTML={{__html: data.video2 }}></div>
        </div>
        <div className="paragraph3">
          <div dangerouslySetInnerHTML={{__html: data.paragraph3 }}></div>
          <div className="container image-container">
            <div className="columns">
              <div className="column is-full-mobile is-one-third-desktop">
                <img src={data.motor1} alt="Motor image1" />
              </div>
              <div className="column is-full-mobile is-one-third-desktop">
                <img src={data.motor2} alt="Motor image2" />
              </div>
              <div className="column is-full-mobile is-one-third-desktop">
                <img src={data.motor3} alt="Motor image3" />
              </div>
            </div>
          </div>
        </div>
        <div className="paragraph4">
          <div dangerouslySetInnerHTML={{__html: data.paragraph4 }}></div>
          <div className="container image-container">
            <div className="columns">
              <div className="column is-full-mobile is-half is-one-quarter-desktop">
                <img src={data.materials1} alt="Materials image1" />
              </div>
              <div className="column is-full-mobile is-half is-one-quarter-desktop">
                <img src={data.materials2} alt="Materials image2" />
              </div>
              <div className="column is-full-mobile is-half is-one-quarter-desktop">
                <img src={data.materials3} alt="Materials image3" />
              </div>
              <div className="column is-full-mobile is-half is-one-quarter-desktop">
                <img src={data.materials4} alt="Materials image4" />
              </div>
            </div>
          </div>
        </div>
        <div className="paragraph5">
          <div dangerouslySetInnerHTML={{__html: data.paragraph5 }}></div>
          <div class="download-link full">
            <a href={ data.document } target="_blank" rel="noreferrer" download><p>Download - Faraday funded projects to date booklet</p> <span class="arrow arrow-down"></span></a>
          </div>
        </div>
        <div className="paragraph6">
          <div dangerouslySetInnerHTML={{__html: data.paragraph6 }}></div>
        </div>
      </div>

      <div className="container pd-h-tch">

        <Swiper
          modules={[Pagination, A11y]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            640: {
              width: 640,
              slidesPerView: 2,
            },
            800: {
              width: 800,
              slidesPerView: 3,
            },
            1024: {
              width: 1024,
              slidesPerView: 4,
            },
            1440: {
              width: 1440,
              slidesPerView: 4,
            },
          }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <a href="https://www.southampton.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image1} alt='University of Southampton'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.cam.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image2} alt='University of Cambridge'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://su.sheffield.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image3} alt='University of Sheffield'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.lsbu.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image4} alt='LONDON SOUTH BANK UNIVERSITY'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.npl.co.uk/" target="_blank" rel="noreferrer">
              <img src={data.image5} alt='NATIONAL PHYSICAL LABORATORY'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.qinetiq.com/en/" target="_blank" rel="noreferrer">
              <img src={data.image6} alt='QINETIQ'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.qmul.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image7} alt='QUEEN MARY UNIVERSITY OF LONDON'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.uji.es/" target="_blank" rel="noreferrer">
              <img src={data.image8} alt='UNIVERSITAT JAUME I'/>
            </a>
          </SwiperSlide>

        </Swiper>

        <div className="partner-button-holder">
          <Link to="/partners" className="button primary partner-button">See all of our partners</Link>
        </div>
      </div>

      </FadeIn>
    </div>
    </>
  );
}
