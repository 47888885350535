import React, { useState, useEffect } from "react";
import axios from 'axios';

import {
  Link
} from "react-router-dom";

export default function Footer() {

  const [homeData, setHome] = useState([]);

  const url = 'https://dginnovate.herokuapp.com/';

  useEffect(() => {

    let three = `${url}homepage`;

    const fetchData = async () => {

      const request3 = await axios(three);

      setHome(request3.data);
    };

    fetchData()

  }, []);


  return (
    <footer className="footer">
      <div className="container pd-h-tch">
        <div className="columns">
          <div className="column is-full-mobile is-one-third">
            <h3 class="footer-title mg-b">Admin</h3>
            <ul className="footer-nav admin">
              <li><Link className="disclaimer footer-link mg-b-sm" to='/disclaimer'><span>Disclaimer</span></Link></li>
              <li><Link className="privacy footer-link mg-b-sm" to='/privacy'><span>Privacy</span></Link></li>
              {/* <li><Link className="accessibility footer-item" to='/accessibility'><span>Accessibility</span></Link></li> */}
              <li><Link className="terms footer-link mg-b-sm" to='/terms'><span>Terms and Conditions</span></Link></li>
              <li><Link className="sitemap footer-link" to='/sitemap'><span>Site Map</span></Link></li>
            </ul>
          </div>

          <div className="column is-full-mobile is-one-third">
            <Link className="investors footer-link footer-title mg-b" to='/investors'><span>Investors</span></Link>
            <ul className="footer-nav">
              <li><Link className="share-price footer-link mg-b-sm" to='/share-price'><span>Share Price</span></Link></li>
              <li><Link className="regulatorynews footer-link mg-b-sm" to='/regulatory-news'><span>Regulatory News</span></Link></li>
              <li><Link className="reports footer-link mg-b-sm" to='/reports-documents'><span>Reports &amp; Documents</span></Link></li>
              {/* <li><Link className="accessibility footer-item" to='/accessibility'><span>Accessibility</span></Link></li> */}
              <li><Link className="directors footer-link mg-b-sm" to='/directors'><span>Directors</span></Link></li>
              <li><Link className="agm footer-link" to='/agm'><span>AGM</span></Link></li>
            </ul>
          </div>
          <div className="column is-full-mobile is-one-third">
            <img src="https://res.cloudinary.com/deregallera/image/upload/v1616674670/dgi-logo.png" className="mg-b" alt="logo" width="175" height="28"/>
            { homeData.address ?
              <div className="company-address">
              Address: { homeData.address }
            </div>
            : null }
            { homeData.phone ?
              <div className="company-phone">
                Call us: { homeData.phone }
              </div>
            : null }
            <ul className="footer-nav">
              <li>
                <Link className="contact" to='/contact'><span>Contact us</span></Link>
              </li>
            </ul>
          </div>
          {/* { homeData.company ?
           <div className="column is-full-mobile is-one-third">
             <div className="company-number">
              { homeData.company }
             </div>
           </div>
           : null }
           { homeData.bsi ?
           <div className="column is-full-mobile is-one-third">
             <img src={ homeData.bsi } alt={ homeData.bsi }/>
             <p className="copyright">{ homeData.copyright }</p>
           </div>
           : null } */}
        </div>
      </div>
    </footer>
  );
}
