import React, { useState, useEffect } from "react";
import axios from 'axios';

import FadeIn from '../shared/fadein';
import { Helmet } from "react-helmet";

export default function Investors() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [data, setData] = useState([]);

  const url = 'https://dginnovate.herokuapp.com/';

  useEffect(() => {
    axios
      .get(`${url}investors`)
      .then(function(response) {
        setData(response.data)
      })
      .catch(error => console.log(error));

  }, []);

  const helmetData = (
    <Helmet>
        <meta name="description" content={`${data.metaDescription}`} />
        <title>{`${data.metaTitle}`}</title>
        <link rel="canonical" href="https://www.dgiplc.com/investors"/>
        <meta property="og:title" content={`${data.metaTitle}`} key="og:title" />
        <meta
          property="og:description"
          content={`${data.metaDescription}`}
          key="og:description"
        />
        <meta name="keywords" content={`${data.keywords}`} />

       {data.preventIndexing ? (
         <meta name="robots" content="noindex"></meta>
       ) : null }
       {data.preventIndexing ? (
         <meta name="googlebot" content="noindex"></meta>
       ) : null }
    </Helmet>
  );

  return (
    <>
    {helmetData}
    <div className="container pd-h-tch">
      <div className="investors">
      <FadeIn delay={200} duration={1200}>
      <h1>Investors</h1>
      <div className="investors-section">
        <div dangerouslySetInnerHTML={{__html: data.content }}></div>
      </div>
      </FadeIn>
      </div>
    </div>
    </>
  );
}
