import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
  Link
} from "react-router-dom";

import FadeIn from '../shared/fadein';
import { Helmet } from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Pagination, A11y } from 'swiper';

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'

export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [data, setData] = useState([]);
  const [newsData, setNews] = useState([]);

  const url = 'https://dginnovate.herokuapp.com/';

  SwiperCore.use([ Pagination ]);

  useEffect(() => {

    let one = `${url}homepage`;
    let two = `${url}articles`;

    const fetchData = async () => {
      const request1 = await axios(one);
      const request2 = await axios(two);

      const newArray = [];
      request2.data.map((item) =>
        item.Other !== true ?
        newArray.push(item)
        : null
      );

      setData(request1.data);
      setNews(newArray);
    };

    fetchData()

  }, []);

  const helmetData = (
    <Helmet>
        <meta name="description" content={`${data.metaDescription}`} />
        <title>{`${data.metaTitle}`}</title>
        <link rel="canonical" href="https://www.dgiplc.com/" />
        <meta property="og:title" content={`${data.metaTitle}`} key="og:title" />
        <meta
          property="og:description"
          content={`${data.metaDescription}`}
          key="og:description"
        />
        <meta name="keywords" content={`${data.keywords}`} />

       {data.preventIndexing ? (
         <meta name="robots" content="noindex"></meta>
       ) : null }
       {data.preventIndexing ? (
         <meta name="googlebot" content="noindex"></meta>
       ) : null }
    </Helmet>
  );

  const thumbItems = newsData.sort((a, b) => a.date > b.date ? -1: 1).map((item) =>
    <div className="column is-full-mobile is-half is-one-quarter-desktop" key={item.id}>
      <div className="overlay-container">
        <div className="news-thumb">
          <a href={"/articles/" + item.slug}><img className="news-image" src={item.image} alt="" /></a>
        </div>
        <div className="overlay">
          <div className="text">{item.name}</div>
          <a href={"/articles/" + item.slug}><button className="button primary inverted">Read more</button></a>
        </div>
      </div>
    </div>
  );

  return (
    <>
    {helmetData}
    <div>

    <FadeIn delay={800} duration={1200}>
      <div className="video-container">
        <video src="shutterstock_1091880105.mov" poster="https://res.cloudinary.com/deregallera/image/upload/v1704631122/home-bg.png" playsInline autoPlay muted loop></video>
        <div dangerouslySetInnerHTML={{__html: data.mission }} class="video-overlay"></div>
      </div>
    </FadeIn>

    <div className="container pd-h-tch">
      <div className="studio-blurb">
        <div dangerouslySetInnerHTML={{__html: data.blurb }}></div>
      </div>
    </div>

    <div className="container pd-h-tch">
      <div className="top-text" dangerouslySetInnerHTML={{__html: data.toptext }}></div>
    </div>

    <div className="container pd-h-tch">
      <div className="columns">
        <div className="column large-6">
          <div className="box-container">
            <div className="green-box">
              <div dangerouslySetInnerHTML={{__html: data.leftlist }}></div>
            </div>
            <div className="grey-box">
              <div dangerouslySetInnerHTML={{__html: data.rightlist }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div className="container pd-h-tch">

        <Swiper
          modules={[Pagination, A11y]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            640: {
              width: 640,
              slidesPerView: 2,
            },
            800: {
              width: 800,
              slidesPerView: 3,
            },
            1024: {
              width: 1024,
              slidesPerView: 4,
            },
            1440: {
              width: 1440,
              slidesPerView: 4,
            },
          }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <a href="https://www.southampton.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image1} alt='University of Southampton'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.cam.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image2} alt='University of Cambridge'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://su.sheffield.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image3} alt='University of Sheffield'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.lsbu.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image4} alt='LONDON SOUTH BANK UNIVERSITY'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.npl.co.uk/" target="_blank" rel="noreferrer">
              <img src={data.image5} alt='NATIONAL PHYSICAL LABORATORY'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.qinetiq.com/en/" target="_blank" rel="noreferrer">
              <img src={data.image6} alt='QINETIQ'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.qmul.ac.uk/" target="_blank" rel="noreferrer">
              <img src={data.image7} alt='QUEEN MARY UNIVERSITY OF LONDON'/>
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a href="https://www.uji.es/" target="_blank" rel="noreferrer">
              <img src={data.image8} alt='UNIVERSITAT JAUME I'/>
            </a>
          </SwiperSlide>

        </Swiper>

        <div className="partner-button-holder">
          <Link to="/partners" className="button primary partner-button">See all of our partners</Link>
        </div>
      </div>

      <div className="container pd-h-tch">
        <div className="bottom-text" dangerouslySetInnerHTML={{__html: data.bottomtext }}></div>
      </div>

      <div className="latest-news-section">
        <div className="overlay"></div>
        <h2 class="mg-b">News</h2>
        <div dangerouslySetInnerHTML={{__html: data.latest }}></div>
        <div className="columns">
          {thumbItems.slice(0, 4)}
        </div>
      </div>

    </div>
    </>
  );
}
