import React, { useState } from "react";
import '../assets/css/mmenu.css';

import {
  Link
} from "react-router-dom";

export default function Nav() {

  const [showMenu, setShowMenu] = useState(false);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
      <a className="navbar-item logo" href="/">
        <img src="https://res.cloudinary.com/deregallera/image/upload/v1616674670/dgi-logo.png" alt="logo" width="175" height="28"/>
      </a>
      <a href="#nav-menu">
        <button className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarMobile">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </a>
      </div>

      <div id="navbarMobile" className='navbar-menu'>
        <div className="navbar-start">
          <nav id="nav-menu">
            <ul>
              <li><Link className="home navbar-item" to='/home' onClick={() => setShowMenu(!showMenu)}><span>Home</span></Link></li>
              <li className="has-children">
                <Link className="about-us navbar-item" to='/about' onClick={() => setShowMenu(!showMenu)}><span>About us</span>
                <span className="arrow arrow-down"></span>
                </Link>
                 <ul>
                  <li><Link className="facilities navbar-item" to='/facilities' onClick={() => setShowMenu(!showMenu)}><span>Facilities</span></Link></li>
                  <li><Link className="esg navbar-item" to='/esg' onClick={() => setShowMenu(!showMenu)}><span>ESG</span></Link></li>
                </ul>
              </li>
              <li>
                <Link className="ebt navbar-item" to='/enhanced-battery-technology' onClick={() => setShowMenu(!showMenu)}><span>Battery Technology</span></Link>
              </li>
              <li>
                <Link className="edt navbar-item" to='/enhanced-drive-technology' onClick={() => setShowMenu(!showMenu)}><span>Drive Technology</span></Link>
              </li>
              <li><Link className="news navbar-item" to='/news' onClick={() => setShowMenu(!showMenu)}><span>News</span></Link></li>
              <li>
                <Link className="contact navbar-item" to='/contact' onClick={() => setShowMenu(!showMenu)}><span>Contact us</span></Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>

    </nav>
  );
}
